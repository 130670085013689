.latest-video {
    background: #fdfdfe;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
    border-radius: 28px;
    width: 100%;
}

.latest-vid-head {
    color: var(--black);
}

.latest-vid-img {
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
}
.new-latest-video{
    height: 220px;
}
.play-vid-btn {
    position: absolute;
    top: 45%;
    left: 45%;
}

.latest-video-info p {
    color: #9DA4BF;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    line-height: 27px;
}

.video-time {
    position: absolute;
    bottom: 10px;
    right: 38px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
}

.watch-btn {
    font-size: 16px;
    padding: 0 24px !important;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    height: 40px !important;
    border: 1px solid var(--theme_pink);
}

.watch-btn img {
    width: 15px;
}

.like-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 45px;
    border-radius: 10px;
    padding: 5px 0;
    height: 40px;
    display: inline-block;
    text-align: center;
}

.video-modal-header {
    border-bottom: 0px;
}

.modal-video-content video {
    width: 100%;
    height: auto;
    border-radius: 16px;
    border: 0px;
    object-fit: contain;
}

.video-cross {
    position: absolute;
    top: 10px;
    right: 5px;
}

.body-radius {
    border-radius: 20px;
    border: none !important;
    background: transparent;
}

.video-cross-img {
    max-width: 75% !important;
    opacity: 1 !important;
    box-shadow: 0px 0px 2px 1px var(--black);
    border-radius: 20px;
    padding: 4px;
    background: var(--black);
}

.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.chanel-content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    position: relative;
    border-radius: 24px 5px;
}

.like-img {
    position: absolute;
    right: 15px;
    top: 10px;
}

.chanel-content .chanl-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
}

.chanel-text p {
    line-height: 10px;
}

.bite-img {
    border-radius: 20px;
}

.trending-text {
    position: absolute;
    border-radius: 13px 4px;
    background: var(--theme_pink);
    color: var(--white);
    padding: 6px 14px;
    left: 23px;
    top: 28px;
}

.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-white {
    padding: 16px 42px;
    color: var(--btn-color);
    background-color: var(--white);
    border: 1px solid #E3E0FE;
    border-radius: 6px 20px !important;
}

.btn-white:hover,
.btn-white:active {
    /* padding: 16px 42px; */
    color: var(--white);
    background-color: var(--theme_blue);
}



/* chanel start */
.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.bite-inner-img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
}

.bite-relative {
    position: relative;
}

.bite-wrapper .common-btn {
    display: inline-flex;
    border-radius: 13px 4px;
    padding: 5px 40px;
    border: none;
    gap: 10px;
    align-items: center;
}

.btn-play {
    position: absolute;
    top: 50%;
    border: none;
    background-color: transparent;
    left: 15%;
    transform: translateY(-50%);
}

.bite-wrapper p {
    line-height: 20px;
    text-overflow: ellipsis;
}


.btn-play-icon {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 60px;
}

.reduce-height {
    max-height: 130px;
    min-height: 130px;
}

.response-padding {
    padding: 15px 0;
}

.response-col {
    padding: 15px 10px;
}

.challenges {
    /* background: url('../image/background_channel.png'); */
    background-repeat: no-repeat;
    background-position: top center;
}

.challenge-head h3 {
    line-height: 26px;
    color: #303030;
}

.challenge-img {
    position: relative;
    top: 35px;
    border-radius: 100%;
    width: 130px;
    height: 130px;
}

.challenge-head p {
    color: #303030;
    line-height: 26px;
}

.video-upload {
    border-radius: 12px;
    width: 100%;
    height: 250px;
    border: 2px solid #8e8e8e;
}

.video-upload video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.playbtn-image {
    position: absolute;
    top: 45%;
    right: 45%;
}

.video-select {
    border: 2px dashed #303030;
    border-radius: 8px;
}

.video-file {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.video-upl-text {
    position: absolute;
    top: 45%;
    right: 35%;
    line-height: 26px;
    letter-spacing: -0.408px;
    color: #303030;
}

.custom-btn {
    width: 400px;
}

.Upload-text {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.408px;
    color: #303030;
}

.challenge-create .common-btn {
    padding: 18px 29px;
}

.createchallenge-wrapper .common-btn {
    padding: 5px 24px !important;
}

.form-select:focus {
    box-shadow: unset;
}

.text-summary {
    border: 1px solid #D1D1D1 !important;
    color: var(--gray-text);
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 16px;
}

.link-img {
    background: var(--input-bg);
    border-radius: 10px;
    margin: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 54px;
}
.embed-input  .border-edit {
 height: 144px;
 padding: 14px 16px;
}
.embed-main-input .or-bg{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.link-input .border-edit {
    border: 0 !important;
}
.embed-main-input .or-bg span {
    background: #fff;
    padding: 0 7px;
}

.bite-preview {
    width: 167px;
    border-radius: 10px

}
.play-bite {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.play-bite .img-bite {
    width: 32px;
    object-fit: contain;
}

.bite-preview img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
@media ((min-width: 1200px) and (max-width: 1400px)) {
    .latest-video-info p {
        font-size: 18px;
    }

    .watch-btn {
        padding: 0 14px !important;
        gap: 5px;
        height: 35px !important;
        font-size: 14px !important;
    }

    .play-vid-btn img {
        width: 30px !important;
    }

    .like-btn {
        width: 35px;
        border-radius: 5px;
        padding: 3px 0;
        height: 30px;
    }

    .video-time {
        position: absolute;
        bottom: 10px;
        right: 20px;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        line-height: 25px;
    }

}

@media screen and (max-width: 992px) {
    .challenges {
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
    }
}

@media screen and (max-width: 567px) {
    .custom-btn {
        width: 300px;
    }

}

@media screen and (max-width: 480px) {

    .custom-btn {
        width: 200px;
    }
}

@media screen and (max-width:1920px) {
    .bite .bite-img {
        max-width: 200px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    img.bite-img.reduce-height {
        max-height: 100px;
        min-height: 100px;
    }

    img.bite-img {
        max-height: 150px;
        min-height: 150px;
        height: 100%;
    }

}

@media screen and (max-width: 1600px) {
    .bite .bite-img {
        max-width: 150px;
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    .btn-play-icon {
        position: absolute;
        top: 50%;
        left: 20%;
        transform: translate(-50%, -50%);
        width: 40px;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .bite .bite-img {
        max-width: 120px;
    }

    .chanel-content {
        gap: 12px;
        padding: 10px;
    }

    .chanel-text {
        gap: 5px !important;
        align-items: flex-start !important;
    }

    .bite-wrapper .common-btn {
        padding: 5px 35px;
        font-size: 16px;
    }
}

@media screen and (max-width:1200px) {
    .chanel-content .chanl-img {
        width: 60px;
        height: 60px;
    }

    .bite .bite-wrapper {
        margin: 0;
    }

    .btn-play {
        top: 120px;
        left: 40%;
    }

    .bite-wrapper {
        margin: 0;
    }

    .btn-play-icon {
        left: 25%;
        width: 40px;
    }

    img.bite-img {
        max-height: 150px;
        min-height: 150px;
    }

    .video-time {
        right: 20px;
    }

}

@media screen and (max-width:991px) {
    .bite .row.gy-4 {
        --bs-gutter-y: 0 !important;
    }

    .play-icon {
        width: 90px;
        height: 90px;
        padding: 28px 33px;
        bottom: 30px;
        right: 0;
    }

    .response-padding {
        padding: 15px 0;
    }

    .btn-play-icon {
        left: 15%;
        width: 40px;
    }

    .bite-wrapper h4 {
        font-size: 20px;
    }

}

@media screen and (max-width:767px) {
    .bite-wrapper p {
        max-width: 160px;
    }

    .modal-react {
        left: auto;
        right: 0;
    }
}

@media screen and (max-width:575px) {

    .btn-play {
        left: 15%;
    }

    .play-icon {
        width: 60px;
        height: 60px;
        bottom: 30px;
        right: 10px;
    }

    .new-latest-video {
        height: 100%;
    }
}

@media screen and (max-width:480px) {
    .bite-wrapper p {
        max-width: 100%;
    }

    .btn-play-icon {
        left: 20%;
    }

    .video-time {
        right: 10px;
    }
}

@media screen and (max-width:420px) {
    .btn-play {
        top: 130px;
        left: 45%;
    }

    .watch-btn {
        font-size: 14px;
        padding: 0 14px !important;
        gap: 5px;
        height: 30px !important;
    }

    .watch-btn img {
        width: 10px;
    }

    .latest-video-info p {
        font-size: 14px;
    }

    .latest-video .d-flex {
        align-items: center;
    }

    .like-btn {
        width: 38px;
        padding: 3px 0;
        height: 35px;
    }

    .bite-wrapper h4 {
        font-size: 16px;
    }

    .chanel-text.mt-4 {
        margin-top: 0.5rem !important;
    }

    .bite .bite-img {
        max-width: auto;
    }
}

@media screen and (max-width:300px) {
    .bite-wrapper h4 {
        font-size: 14px;
    }

    .bite-wrapper .common-btn {
        padding: 5px 25px;
        font-size: 16px;
    }
}


.round-radio {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
}
.round-radio label {
    display: inline-block;
}
.round-radio .main {
    display: block;
    position: relative;
}
.round-radio input[type=radio] {
    visibility: hidden;
}
.round-radio .main input:checked ~ .custom-check {
    background: var(--theme_pink) !important;
    border-radius: 50px;
    border-color: #fff;
}
.round-radio .custom-check {
    position: absolute;
    top: 2px;
    right: 0px;
    height: 20px;
    width: 20px;
    border: 1px solid #9D9D9D;
    background: #fff;
    border-radius: 50px;
}
.round-radio .main input:checked ~ .custom-check:after {
    display: block;
}
.round-radio .main .custom-check:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 4px;
    opacity: 1;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 10px;
    z-index: 9999;
}
.border-round {
    border-top: 0px !important;
    border-radius: 0px 0px 10px 10px;
}
.round-radius {
    border-radius: 10px 10px 0 0;
}
.selectseries-listing {
    display: grid;
    grid-template-columns: 120px auto 20px;
    gap: 16px;
    align-items: center;
}
.selectseriesimg {
    width: 120px;
    height: 90px;
    border-radius: 10px;
}
.series-listing{
    max-height: 600px;
    overflow-y: auto;
}
.series-listing li {
    border-bottom: 1px solid rgba(48, 48, 48, 0.16);
    padding: 16px 0;
}
.bite-height {
    height: 442px;
    overflow-y: auto;
}   
@media (max-width:325px){
    .selectseries-listing {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 37px;
    }
}
.left-corner{
    border-radius: 20px 6px !important;
}