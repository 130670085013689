.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-white {
    padding: 16px 42px;
    color: var(--btn-color);
    background-color: var(--white);
    border: 1px solid #E3E0FE;
    border-radius: 6px 20px;
}

.btn-white:hover,
.btn-white:active {
    padding: 16px 42px;
    color: var(--white);
    background-color: var(--theme_blue);
}

/* chanel start */
.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.chanel-content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    position: relative;
    border-radius: 24px 5px;
}

.chanel-content .chanl-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
}

.chanel-text p {
    line-height: 10px;
}

.bite-inner-img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
}

.bite-relative {
    position: relative;
}

.bite-wrapper .common-btn {
    display: inline-flex;
    border-radius: 13px 4px;
    padding: 5px 40px;
    border: none;
    gap: 10px;
    align-items: center;
}

.btn-play {
    position: absolute;
    top: 50%;
    border: none;
    background-color: transparent;
    left: 15%;
    transform: translateY(-50%);
}

.bite-wrapper p {
    line-height: 20px;
    text-overflow: ellipsis;
}

.challenge-video {
    position: relative;
}

.img-relative {
    width: 100%;
}

.video-bottom {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.video-bottom img {
    width: 55px;
    height: 55px;
}

.video-bottom p {
    line-height: 15px;
}

.video-bottom h6 {
    font-size: 16px;
}

.video-bg {
    position: relative;
}

.bg-image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
}


.play-icon {
    position: absolute;
    background: #cc09a5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 28px 33px;
    bottom: 20px;
    right: 10px;
    border-color: #cc09a5;
    outline: none;
    box-shadow: none;
    border: 1px transparent;
}

.play-icon img {
    width: 49px;
}

.video-detail {
    display: flex;
}

.chllge-img {
    width: 63px;
    height: 63px;
    margin-right: 10px;
}

.grid-frame {
    display: grid;
    grid-template-columns: 500px 500px 500px;
    gap: 30px;
    cursor: pointer;
    height: 440px;
}

.grid-column {
    background-color: var(--white);
    padding: 10px;
    border-radius: 25px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    height: 430px;
    width: 100%;
    left: 5px;
}

.challene-wrapper {
    overflow-x: scroll;
}

/* width */
::-webkit-scrollbar {
    display: none;
}

.challene-wrapper {
    width: 100%;
    overflow-x: scroll;
}

.comming-soon .owl-carousel .owl-nav.disabled {
    display: none !important;
}

.img-video,
.img-relative,
.bite-img {
    border-radius: 13px;
}

.img-video {
    width: 100%;
}

/* challenge end */

/* modal  */
.play-video span.carousel-control-prev-icon {
    width: 80px;
    height: 80px;
    background: var(--theme_pink);
    position: absolute;
    left: -120px;
    border-radius: 50%;
    font-size: 50px;
}

.play-video .carousel-control-next-icon {
    right: -120px;
    width: 80px;
    height: 80px;
    background: var(--theme_pink);
    position: absolute;
    border-radius: 50%;
    font-size: 50px;
}

.play-video .modal-content {
    background-color: transparent;
    border: none;
}

.play-video .slider-image {
    border-radius: 30px;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.2);
}

.play-video .slider-images {
    border-radius: 30px;
}

.play-video .video-bottom {
    position: absolute;
    bottom: 10px;
    left: -40px;
    text-align: left;
}

.carousel-control-prev,

.carousel-control-next {
    width: 0;
}

.play-video .like-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 55px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 0;
}

.play-video .share-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 55px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 0;
    margin-top: 10px;
}

.respns-wrapper {
    position: absolute;
    bottom: 20%;
    right: 30px;
}

.slider-image {
    position: relative;
}

@media screen and (max-width:1920px) {
    .bite .bite-img {
        max-width: 120px;
        ;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    img.bite-img {
        max-height: 150px;
        min-height: 150px;
        height: 100%;
    }
}

@media screen and (max-width: 1600px) {
    .bite .bite-img {
        max-width: 150px;
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .bite .bite-img {
        max-width: 80px;
    }

    .chanel-content {
        gap: 12px;
        padding: 10px;
    }

    .chanel-text {
        gap: 5px !important;
        align-items: flex-start !important;
    }

    .bite-wrapper .common-btn {
        padding: 5px 35px;
        font-size: 16px;
    }

    .bite-wrapper h4 {
        font-size: 18px;
    }

    .bite-wrapper .common-btn {
        padding: 5px 28px;
        font-size: 14px;
    }
}

@media screen and (max-width:1200px) {
    .chanel-content .chanl-img {
        width: 60px;
        height: 60px;
    }

    .bite .bite-relative {
        width: 100%;
    }

    .bite .bite-wrapper {
        margin: 0;
    }

    .btn-play {
        top: 120px;
        left: 40%;
    }

    .bite-wrapper {
        margin: 0;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .chanel-text h5 {
        font-size: 18px;
    }
}

@media screen and (max-width:991px) {
    .bite .row.gy-4 {
        --bs-gutter-y: 0 !important;
    }

    .btn-play {
        top: 80px;
        left: 10%;
    }

    .video-bg:after {
        width: 100%;
    }


    ::-webkit-scrollbar {
        width: 20px;
        display: block;
        height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px var(--btn-color);
        border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--btn-color);
        border-radius: 10px;
    }

    .play-icon img {
        width: 38px;
    }

    .play-icon {
        width: 90px;
        height: 90px;
        padding: 28px 33px;
        bottom: 30px;
        right: 0;
    }

    .response-padding {
        padding: 15px 0;
    }

    .response-col {
        padding: 15px 10px;
    }

    .channel .g-5,
    .gy-5 {
        --bs-gutter-y: 1rem !important;
    }

    .bite-wrapper {
        margin: 0;
    }

    .play-video span.carousel-control-prev-icon {
        left: -40px;
    }

    .play-video .carousel-control-next-icon {
        right: -40px;
    }

}

@media screen and (max-width:767px) {

    .bite .bite-relative {
        width: auto;
    }

    .bite-wrapper p {
        max-width: 160px;
    }

    .btn-play img {
        width: 40px;
    }

    .btn-play {
        top: 95px;
        left: 21%;
    }

    .play-video span.carousel-control-prev-icon,
    .play-video .carousel-control-next-icon {
        width: 60px;
        height: 60px;
        font-size: 40px;
    }

    .play-video span.carousel-control-prev-icon {
        left: 0;
    }

    .play-video .carousel-control-next-icon {
        right: 0;
    }

    .grid-column {
        height: 330px;
    }

    .grid-frame {
        grid-template-columns: 350px 350px 350px;
        gap: 20px;
        height: 330px;
    }

    .play-icon {
        width: 60px;
        height: 60px;
    }

    .play-icon img {
        width: 25px;
    }

    .play-icon {
        padding: 20px 24px;
    }

}

@media screen and (max-width:575px) {

    .btn-play {
        left: 15%;
    }

    .grid-frame {
        grid-template-columns: 250px 250px 250px;
        height: 300px;
    }

    .grid-column {
        height: 290px;
    }

    .chllge-img {
        width: 55px;
        height: 50px;
        object-fit: cover;
    }

    .play-icon {
        width: 60px;
        height: 60px;
        bottom: 30px;
        right: 10px;
    }

    .chanel-content {
        gap: 22px;
        padding: 20px;
    }

    .bite .bite-img {
        max-width: 40%;
    }
}

@media screen and (max-width:480px) {
    .bite .bite-relative {
        width: 50%;
    }

    .bite-wrapper p {
        max-width: 100%;
    }

    .btn-play {
        top: 135px;
        left: 45%;
    }

    .play-video span.carousel-control-prev-icon {
        width: 40px;
        height: 40px;
        left: -10px;
        font-size: 27px;
    }

    .play-video .carousel-control-next-icon {
        width: 40px;
        height: 40px;
        right: -10px;
        font-size: 27px;
    }

    .video-bottom img {
        width: 35px;
        height: 35px;
    }

    .play-video .video-bottom {
        left: -30px;
    }

    .respns-wrapper {
        right: 10px;
    }

    .play-video .like-btn,
    .play-video .share-btn {
        width: 45px;
    }

    .chanel-content {
        gap: 20px;
        padding: 20px;
    }
}

@media screen and (max-width:420px) {
    .bite .bite-relative {
        width: 100%;
    }

    .btn-play {
        top: 130px;
        left: 45%;
    }

    .chanel-content {
        gap: 12px;
        padding: 15px 30px 15px 10px;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .bite-wrapper h4 {
        font-size: 18px !important;
    }

    .bite .bite-wrapper .common-btn {
        padding: 5px 25px;
        font-size: 16px;
    }

    .bite .bite-img {
        max-width: 50%;
    }

}

@media screen and (max-width:320px) {
    .bite .bite-wrapper .common-btn {
        padding: 5px 20px;
        font-size: 14px;
    }

    .bite-wrapper h4 {
        font-size: 16px !important;
    }

    .chanel-text h5,
    .chanel-text p {
        font-size: 15px;
        margin: 5px 0;
    }

    img.bite-img {
        max-height: 105px;
        min-height: 100px;
    }

    .bite .bite-img {
        max-width: 40%;
    }

    .bite img.bite-img {
        max-width: initial !important;
    }

    .chanel-content {
        padding: 15px 12px 15px 10px !important;
    }
}

.app_videos {
    position: relative;
    width: 68%;
    max-width: 450px;
    height: 100%;
    /* background-color: black; */
    scroll-snap-type: x mandatory;
    border-radius: 20px;
    margin: 0px auto;
}

.app_videos::-webkit-scrollbar {
    display: none;
}

.videoCard {
    position: relative;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
}

.videoCard_player {
    object-fit: fill;
    height: 100%;
    width: 100%;
    border-radius: 20px;
}


.bucket-btn {
    border-radius: 50px;
}

.left-series-icon {
    position: absolute;
    bottom: 16px;
    right: 16px;
    filter: opacity(0.5);
}

.month-select {
    background: #EBEDF0;
    border-color: #EBEDF0 !important;
}

.form-select {
    background-image: url(/public/assets/images/creater-dashboard/downaarow.svg);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    filter: opacity(0.5);
}


.bucket-wrapper {
    border-radius: 30px;
    background: #FFF;
    position: relative;
    padding: 38px 20px;
}

.addbucket-wrapper {
    padding: 38px 20px;
    border-radius: 30px;
}

.bucket-wrapper::before {
    content: "";
    position: absolute;
    background: url(/public/assets/images/creater-dashboard/pink-overlay.png);
    height: 174px;
    width: 100%;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    top: 0;
}

.img-top-bucket {
    width: 169px;
    height: 120px;
    position: relative;
    z-index: 99;
}

.bucket-price {
    font-size: 30px;
}

.bucket-btn-month p {
    background-color: #FAEBF5;
    border-radius: 20px;
    padding: 0px 20px;
}

.getaccess_text h2 {
    font-size: 24px;
    color: #303030;
}

.number-count {
    font-size: 40px;
    font-weight: 800;
}

.bite-wrapper-series h4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 20px;
    font-weight: 600;
}

.bite-wrapper-series .chanel-text p {
    overflow: visible !important;
}

.bucketseries-custom .channelbite-img {
    height: 106px;
    width: 167px;
    object-fit: cover;
}

.dots_bucketseries {
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    background: hsla(0, 0%, 74%, .3);
    border-radius: 10px;
    color: var(--white);
    display: inline-block;
    padding: 6px 0;
    position: absolute;
    right: 4px;
    top: 4px;
    text-align: center;
    width: 38px;
    height: 38px;
}

.bucketseries-custom {
    border-radius: 15px !important;
    background: #D9D9D9;
}

.bucket-inner {
    border-radius: 30px 30px 0px 0px;
    background: #FFF;
}

.bucket-inner .btn-white {
    border-radius: 20px 6px;
    border: 1px solid #E3E0FE !important;
}

/* choose series */
.chooseseries-inner {
    border-radius: 15px;
    background: #D9D9D9;
    display: grid;
    grid-template-columns: 120px auto 37px;
    gap: 12px;
    align-items: center;
    padding: 19px;
}

.choose-wrapper {
    border-radius: 30px 30px 0px 0px;
    background: #FFF;
}

.chooseinner-img {
    width: 120px;
    height: 107px;
    border-radius: 8px;
    object-fit: cover;
}

.roundcheckbox {
    position: relative;
}

.roundcheckbox label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.roundcheckbox label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.roundcheckbox input[type="checkbox"] {
    visibility: hidden;
}

.roundcheckbox input[type="checkbox"]:checked+label {
    background-color: var(--theme_pink);
    border-color: var(--theme_pink);
}

.roundcheckbox input[type="checkbox"]:checked+label:after {
    opacity: 1;
}
@media (max-width:390px){
    .chooseinner-img {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        object-fit: cover;
    }
    .chooseseries-inner {
        grid-template-columns: 80px auto 27px;
    }
}
@media(max-width:350px){
    .chooseseries-inner {
        display: block;
    }
    .chooseinner-img {
        width: 100%;
        height: 130px;
        border-radius: 8px;
        object-fit: cover;
    }
    .chooseinner-heading{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
        -webkit-box-orient: vertical;
    }
   
}

@media (max-width:290px) {
    .bucket-btn-month p {
        padding: 0px 16px;
    }
}