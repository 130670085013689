body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: var(--font-family);
}

* {
  margin: 0px;
  padding: 0px;
}

img {
  margin: 0px;
  padding: 0px;
}

html,
body {
  overflow-x: hidden;
  font-family: "Gilroy";
}

:root {
  --font-family: "Gilroy";
  --black: #000;
  --theme_blue: #07143a;
  --theme_pink: #ca3392;
  --white: #fff;
  --gradient: linear-gradient(76.62deg,
      #baf6fb -24.34%,
      #8e9aff 29.53%,
      #b48afd 80.19%);
  --gray-text: #bababa;
  --sidebar-bg: #e6e6f5;
  --channel-text: #303030;
  --channel-para: #a0a0a0;
  --video-text: #9DA4BF;
  --side-text: #737373;
  --create-text:#CBCBCB;
  --new-text:#A9A9A9;
  --input-bg:#d6d6d64d;
}

/* graphic designer */
h1 {
  font-size: 70px;
  color: var(--theme_blue);
  font-weight: 700;
}

h2 {
  font-size: 50px;
  color: var(--theme_blue);
  font-weight: 700;
}

h3 {
  font-size: 30px;
  color: var(--theme_blue);
}

h4 {
  font-size: 24px;
  color: var(--theme_blue);
}

h5 {
  font-size: 18px;
  color: var(--theme_blue);
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
}

h2,
h3,
h4,
h5,
h6,
a,
p,
li {
  word-break: break-word;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

.f9-size {
  font-size: 9px;
}
.f11-size {
  font-size: 11px;
}
.f12-size {
  font-size: 12px;
}

.f13-size {
  font-size: 13px;
}

.f14-size {
  font-size: 14px;
}

.f15-size {
  font-size: 15px;
}

.f17-size {
  font-size: 17px;
}

.f18-size {
  font-size: 18px;
}

.f19-size {
  font-size: 19px;
}

.f20-size {
  font-size: 20px;
}
.line-24 {
  line-height: 24px;
}

.br40 {
  border-radius: 40px;
}

a {
  text-decoration: none;
}
.input-bg{
  background-color: var(--input-bg);
}

.channel-text {
  color: var(--channel-text);
}

.border5 {
  border: 5px solid var(--white);
}

.py-100 {
  padding: 100px 0px;
}

.py-80 {
  padding: 80px 0px;
}

.pink-text {
  color: var(--theme_pink);
  font-size: 120px;
}

.blue-text {
  color: var(--theme_blue) !important;
}

.text-pink {
  color: var(--theme_pink) !important;
}

.video-text {
  color: var(--video-text);
}
.new-text{
  color: var(--new-text);
}

a:hover {
  text-decoration: none;
}

/* button */
.common-btn {
  display: inline-block;
  background-color: var(--theme_pink);
  color: var(--white);
  border-radius: 30px 10px;
  padding: 18px 70px;
  text-align: center;
  font-size: 18px;
  border: none !important;
}

.common-btn:hover {
  background-color: var(--theme_pink) !important;
  color: var(--white);
}

.btn-blue {
  background-color: var(--theme_blue);
  color: var(--white) !important;
  padding: 18px 63px;
}
.create-text{
  color: var(--create-text);
  
}
.form-control:focus {
  border-color: transparent;
  box-shadow: unset;
}

.channel-text {
  color: var(--channel-text);
}

.channel-para {
  color: var(--channel-para);
}

ul {
  list-style: none;
}

.py-100 {
  padding: 100px 0px;
}

.py-60 {
  padding: 60px 0px;
}

.f-70 {
  font-size: 70px;
}

.pink {
  color: var(--theme_pink);
}
.theme-bg{
  background-color: var(--theme_pink);
  padding: 10px;
  border-radius: 30px;
  text-align: center;
}
/* global ends*/

/* header */

/*header ends*/

/* common section start */
/* popular channel start  */

/* / testimonial end  / */

/*footer starts */


/*footer ends*/

/* common section end */


/*for global again*/
/* responsive common start */
@media screen and (max-width: 1600px) {
  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .py-100 {
    padding: 90px 0;
  }

  .py-60 {
    padding: 40px 0;
  }

  .font-120 {
    font-size: 80px;
  }

  .f-70 {
    font-size: 50px;
  }

  .font-120 {
    font-size: 80px;
  }

  .font-70 {
    font-size: 50px;
  }
}

@media screen and (max-width: 1200px) {
  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
  }

}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 22px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  .py-80 {
    padding: 50px 0;
  }

  .py-60 {
    padding: 30px 0;
  }

  .channel-bites-content {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14) !important;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }
}



/* stream btn  */
.stream-btn {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  gap: 16px;
}

.chanel-content {
  margin: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.14);
}

.bottom-slider .swiper-slide {
  width: auto;
  display: flex;
  align-items: center;
}

.bottom-slider .carousel .slide {
  margin-right: 10px;
}

.bottom-slider .owl-stage-outer {
  overflow: hidden;
  height: 80px;
}

.bottom-slider {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 80px;
}

/* responsive common end for global css */


.video-modal-header {
  border-bottom: 0px;
}

.modal-video-content video {
  width: auto;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  /* border: 3px solid #8e8e8e; */
  max-width: 100%;
  max-height: 600px;
}

.video-cross {
  position: absolute;
  top: 10px;
  right: 5px;
}

.body-radius {
  border-radius: 20px;
  border: none !important;
  background: transparent;
  width: auto;
}

.video-cross-img {
  max-width: 75% !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 2px 1px #000000;
  border-radius: 20px;
  padding: 4px;
  background: #000;
}

.modal-video-content {
  border-radius: 20px;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
}

/* 12-june-23 */
.bite-wrapper .chanel-text {
  margin-bottom: 12px;
}

.like-img-box {
  position: absolute;
  top: 17px;
  right: 31px;
}

.video-carousel {
  position: absolute;
  bottom: 14px;
  color: #fff;
  padding: 8px 17px;
}

.share-like-main {
  position: absolute;
  bottom: 10%;
  right: 8px;
}

.like-video {
  background: rgba(188, 188, 188, 0.3);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  color: var(--white);
  width: 48px;
  border-radius: 6px;
  text-align: center;
  padding: 10px 10px;
}

.share-video {
  background: rgba(188, 188, 188, 0.3);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  color: var(--white);
  width: 48px;
  border-radius: 6px;
  text-align: center;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
}

.like-video img {
  filter: brightness(1.5);
  margin-right: 0px !important;
  margin-left: 4px;
}

.share-video img {
  width: 19px;
  height: 19px;
  object-fit: contain;
  margin-right: 0px !important;
}

span.like-count {
  font-size: 13px;
  letter-spacing: -0.408px;
}

@media (max-width:992px) {
  .play-video .carousel-control-next {
    right: -85px;
  }

  .play-video .carousel-control-prev {
    left: -85px;
  }

  .play-video span.carousel-control-prev-icon {
    width: 40px !important;
    height: 40px !important;
    font-size: 27px !important;
  }

  .play-video .carousel-control-next-icon {
    width: 40px !important;
    height: 40px !important;
    font-size: 27px !important;
  }
}

@media (max-width:575px) {
  .play-video .carousel-control-next {
    right: -45px;
  }

  .play-video .carousel-control-prev {
    left: -45px;
  }
}

@media (max-width:1600px) {

  .play-video span.carousel-control-prev-icon {
    width: 60px;
    height: 60px;
    font-size: 42px;
  }

  .play-video .carousel-control-next-icon {
    width: 60px;
    height: 60px;
    font-size: 42px;
  }
}

.loader {
  width: 100% !important;
  margin: auto !important;
  z-index: 9999 !important;
  position: absolute;
  display: flex;
  height: '100%';
  justify-content: center;
  align-items: center;
}

.custom-videoplayer video {
  width: 100%;
  height: 100vh;
  object-fit: contain;
}
.custom-trailorplayer video {
  width: 100%;
}
.custom-trailorplayer {
  width: 100%;
  height: 100%;
}

.custom-videoplayer {
  width: 100%;
  height: 100%;
}

.video-back-btn {
  position: absolute;
  /* z-index: 111; */
  top: 50px;
  left: 80px;
}

/* no-data-found */

.no-data-found {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;

}


.latest-video-cus .latest-series-name {
  display: grid;
  grid-template-columns: auto 120px;
  align-items: center;
  gap: 10px;
}

.latest-video-cus .common-btn.series-watch {
  padding: 0px 0px !important;
}

.series-latest-name p {
  line-height: 30px;
}

.img-width {
  height: 270px;
}

/* @media (min-width:576px) {
  .container, .container-sm {
      max-width: 720px;
  }
}

@media (min-width:768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width:992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1120px;
  }
}

@media (min-width:1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1300px;
  }
}

@media (min-width:1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1600px;
  }
}

@media (min-width:1700px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1920px;
  }
} */
@media (min-width:576px) {

  .container,
  .container-sm {
    max-width: 540px
  }
}

@media (min-width:768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px
  }
}

@media (min-width:992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px
  }
}

@media (min-width:1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px
  }

}

@media (min-width:1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px
  }
}

@media (min-width:1700px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1620px
  }
}


@media (min-width:1600px) and (max-width:1800px) {
  .chanel-content {
    padding: 10px;
  }

  .bite-wrapper .common-btn {
    padding: 5px 22px;
  }
}

.video-back-control {
  color: #fff;
  font-size: 30px;
  position: relative;
  z-index: 1;
}
.video-back-control-episode {
  color: black;
  font-size: 30px;
  position: relative;
  z-index: 1;
}

@media(max-width:400px) {
  .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 200px;
    object-fit: cover;
  }
}

.carousel .carousel-status {
  display: none;
}

.carousel .control-dots .dot {
  background: #fff;
  opacity: 1 !important;
}

.no-search-results {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
}

.no-search-results img {
  width: 400px;
  height: 400px;
}

@media (min-width:1920px) {
  .bite .bite-img {
    max-width: 120px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .bite-img {
    max-height: 150px;
    min-height: 150px;
    height: 100%;
  }
}

@media (min-width:1700px) {
  .episode-carousel-main .banner-video {
    height: 600px;
  }
}

@media(max-width:1699px) {
  .form-search-second input {
    height: 52px;
  }

  .header-top {
    top: 0px;
    padding: 0px 0px 0px;
  }

  .header-top {
    height: 87px;
    padding: 0px 0px 0px;
  }

  .main-inner-content {
    top: 124px;
    margin: 0 10px 0 10px;
  }

  .episode-carousel-main .banner-video {
    height: 500px;
  }
}

.header-top {
  height: 87px;
}

.top-dashboard {
  padding: 0px 20px 20px;
  display: flex;
}

/*main-serach */
.form-allchannel-search {
  width: 50%;
}

@media (max-width:991px) {
  .form-allchannel-search {
    width: 100%;
  }

  /* bites */
  .channel-bites-content {
    margin: 3px;
    box-shadow: none !important;
  }
}

/* 19-06-2023 */
.p-height p {
  line-height: 23px;
  padding: 0;
}
.header-search .form-inline {
  border: 1px solid #e3e0fe;
  height: 50px;
  border-radius: 15px;
  background: #fff;
  width: 210px;
  position: relative;
}

.search-icon {
  border: none;
  position: absolute !important;
  background: var(--theme_pink) !important;
  height: 50px;
  width: 48px;
  padding: 5px 1px;
  border-radius: 15px;
  right: -1px;
  top: -1px;
}

.search-icon .search-img {
  filter: brightness(100);
}

.btn-shape {
  border-radius: 6px 20px !important;
  padding: 16px 42px !important;
  height: 58px !important;
}

.white-height .btn-white {
  height: 58px !important;
}

.header-search .form-inline .form-control {
  border: none;
  background: transparent;
  width: 160px;
}

.header-search .form-inline .form-control {
  border: none;
  background: transparent;
  width: 160px;
  position: absolute;
  top: 6px;
  color: #000;
  cursor: pointer;
}

.login-info p {
  width: 60px ;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
}
@media (max-width:578px){
  /* .login-info p{
      display: none;
  } */
  /* .login-info {
  justify-content: end;
} */
  }

@media (max-width:1400px) {
  .img-width {
    height: 180px;
  }
}

@media screen and (max-width:1200px) {
  .stream-btn .common-btn.btn-blue {
    padding: 17px 40px !important;
  }
}

@media (max-width:991px) {
  .form-allchannel-search {
    width: 100%;
  }

  .img-width {
    height: auto;
  }

  .form-allchannel-search {
    width: 100%;
  }

  .img-width {
    height: auto;
  }

  .video-back-btn {
    position: absolute;
    top: 50px;
    left: 10px;
  }
  /* 22-june-23 */
    .epsoide-slider-custom .episode-img-thumb {
      width: 200px;
      height: 200px;
  }
  .play-epi-slider {
      width: 26px;
      height: 41px;
  }
  .trailor-slider-cus .movie-more {
    width: 200px;
    height: 160px;
  }
  .trailor-play .eps-play img {
    width: 26px;
    height: 41px;
  }
  .muvi-slid-img {
    width: 202px;
    height: 190px;
  }
  .like-btn-muvi {
    right: 15px;
  }
  .muvi-slider-title p {
    line-height: 15px;
  }
  .frame-video-main .frame-video-img .video-frame-fluid {
    height: 200px;
}
.favchannel-main-cus .latest-vid-img {
  height:210px;
}
.channel-detail-custom .latest-vid-img {
  height: 144px;
  object-fit: cover;
}
}
@media (max-width:768px) {
  .custom-videoplayer video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding-top: 10px;
  }
}

@media (max-width:480px) {
  .header-search .form-inline {
    width: 150px;
  }

  .interactive-wrapper .bite-wrapper {
    text-align: left;
  }

  .interactive-wrapper .btn-play {
    left: 41%;
  }

  .interactive-wrapper .chanel-text {
    justify-content: initial;
  }

  .interactive-wrapper .bite-relative {
    width: 100%;
    max-width: 120px;
  }

  .interactive-wrapper .bite-img-cus {
    width: 120px;

  }

  .interactive-wrapper .chanel-content-cus {
    grid-template-columns: 116px auto;
  }
}

@media (max-width:578px) {
  .header-search .form-inline .form-control {
    display: none;
  }
  .header-search .form-inline {
    width: 48px;
    border: 0;
  }
}

@media (max-width: 446px) {
  .favourite-cus .bite-img-fav.img-width {
    width: 100% !important;

  }
}

.btn.btn-filter {
  box-shadow: none;
}

.chanel-text p,
.chanel-text h6 {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1;
  -webkit-box-orient: vertical !important;
  height: 22px;
}

.bite-inner-img {
  object-fit: cover;
  background: #fff;
}

@media (max-width:340px) {
  .chanel-text h4 {
    font-size: 14px;
  }

  .bite-img.reduce-height {
    max-width: 100px !important;
  }

  .interactive-wrapper .chanel-content-cus {
    display: block;
  }

  .interactive-wrapper .bite-img-cus {
    width: 100%;
    margin-bottom: 14px;
  }

  .interactive-wrapper .bite-relative {
    max-width: 100%;
  }
}

/* header remover */


.video-content-none .header-top {
  display: none;
}

.class-side {
  display: none;
  width: 0px;
}

.class-main {
  width: 100%;
}

.class-main .custom-videoplayer video {
  height: 99.5vh;
}

/* modal videoplayer */
.modalvideoplayer .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.modalvideoplayer .modal-content {
  background-color: #000;
}

.modalvideoplayer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: #000;
}
.epispdePlayer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: #fff;
}

.episodelist-box {
  box-shadow: none;
}

.episodelist-box .btn-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

.episodelist-box .btn-play-icon img {
  width: 61px;
  height: 61px;
  object-fit: contain;
}
input:focus-visible {
  outline-offset: 0px;
  outline: 0;
}
.side_text{
color: var(--side-text);
}
.iframe-head{
  height: 100dvh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe-head iframe{
  height: auto;
  object-fit: contain;
  background-size: cover;
}
.video_wrapper {
  position: relative;
  /* padding-bottom: 56.25%; */
  padding-bottom: 49%;

  }
  .video_wrapper iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }

  

@media (max-width:576px) {
  .episode-list-main .bite-img {
    max-width: 100%;
  }

  .episode-list-main .bite-relative {
    width: 100%;
  }

  .episodelist-box .btn-play-icon img {
    width: 40px;
    height: 40px;
  }
}
.ytp-more-videos-view .ytp-suggestions {
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  display: none !important;
}

@media (max-width:1920px) {}

.episode-list-main .bite-relative .bite-img {
  max-width: 220px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width:1201px) and (max-width:1460px) {
  .like-img-box {
    position: absolute;
    top: 8px;
    right: 34px;
  }

  .channel-head-space h4 {
    margin-bottom: 3px !important;
  }

  .channel-head-space h4 {
    padding-top: 10px;
  }
}

@media (min-width:992px) and (max-width:1200px) {
  .like-img-box {
    top: 8px;
  }

  .channel-head-space h4 {
    font-size: 18px;
  }

  .channel-head-space h4 {
    margin-bottom: 3px !important;
  }

  .channel-bites-content {
    box-shadow: none !important;
  }
}

@media (min-width:1400px) and (max-width:1600px) {
  .bite .bite-img {
    max-width: 95px;
  }

  /* bites */
  .channel-bites-content {
    gap: 7px !important;
  }

  .channelbite-img {
    width: 100px;
  }

  .channel-bites-content {
    box-shadow: none !important;
  }
}

/* bites pages */
.channelbite-img {
  width: 178px;
  height: 130px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.channel-bites-content {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
  position: relative;
  border-radius: 24px 5px;
  background: #ffff;
}
.video-seriesdetail {
  height: 500px;
  object-fit: cover;
  object-position: top center;
}

@media (max-width:1890px) {
  .channelbite-img {
    width: 110px;
  }
}

@media (max-width:330px) {
  .channel-bites-content {
    display: block;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.14) !important;
  }

  .channelbite-img {
    width: 100%;
  }
}

@media (min-width:331px) and (max-width:767px) {
  .channelbite-img {
    width: 40%;
  }

  .challenge-pic-cus img,
  .challenge-upload-custom .challenge-img {
    width: 83px;
    height: 83px;
  }

}

.close-slider {
  position: absolute;
  right: 6px;
  z-index: 99999999999;
  top: 8px;
  background: #0009;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
}

@media (min-width:992px) and (max-width:1400px){
  .channel-detail-custom .latest-vid-img {
    height: 209px;
}
.muvi-slid-img {
  width: 310px;
  height: 230px;
}
.muvi-slid-img {
  width: 310px;
  height: 230px;
}
.epsoide-slider-custom .episode-img-thumb {
  width: 267px;
  height: 200px;
}
.play-epi-slider,.trailor-play .eps-play img {
  width: 40px;
  height: 60px;
}
}
@media(max-width:767px){
  .forgot-body {
    width: 100%;
  }
  .cusnew-chat-msg .get-msg{
    height: 80%;
  }
  .new-cus-chat .chat-right{
    height: 98%;
  }
}

.data-main {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.comment-input{
  border-bottom: 1px solid #73737340 !important;
}
.comment-user-profile {
  width: 54px;
  height: 54px;
  border-radius: 100%;
  min-width: 54px;
}
.comment-profile-pic {
  object-fit: cover;
  max-width: 100%;
  border-radius: 100%;
  width: 54px;
  height: 54px;
}
.multiple-reply-text {
  border-radius: 10px;
  background: #000;
  color: #fff;
  font-size: 14px;
  padding: 8px 18px 8px 14px;
}
@media screen and (max-width:1600px) {
  .video-seriesdetail {
    height: 465px;
    object-fit: cover;
    object-position: top center;
  }
}
@media screen and (max-width:1440px) {
  .latest-video {
    width: 105%;
  }
  .custom-common-btn{
    padding: 18px 40px;
  }
  .cus-series-chat .cus-chat-right {
    height: 84%;
}
.new-chat-msg .cus-get-msg {
  height: 68%;
  overflow-y: scroll;
}
.video-seriesdetail {
  height: 435px;
}
}
@media screen and (max-width:992px) {
  .order-one {
    order: 1;
  }
  .order-two {
    order: 2;
  }
  .order-three {
    order: 3;
  }
   .order-four {
    order: 4;
  }
  .new-cus-series-chat{
    position: static;
    width: 100%;
  }
  .cus-series-chat .cus-chat-right {
    height: 100%;
  }
  .cus-chat-bottom{
    width:96%;
  }
  .cus-live-chat {
    position: relative;
    margin-top: 10px;
    width: 100%;
    right: unset;
    padding-left: 0px;
}
.cus-live-chat .chat-right{
  height: 100%;  
}
.custom-chat-bottom{
  width: 98%;
}
}

 .like-fav-cus {
  position: absolute !important;
  bottom: 13px !important;
  right: 31px;
  background: rgba(188, 188, 188, 0.3);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  color: var(--white);
  width: 45px;
  border-radius: 10px;
  padding: 5px 0;
  height: 40px;
  display: inline-block;
  text-align: center;
}
.like-fav-cus .fav-like-series {
  filter: initial !important;
  margin-top: 5px;
}
.buy-btn{
  padding: 0px 4px !important;
}
.buy-btn-shop{
  padding: 0px 9px !important;
}
.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: #d1d1d1 !important;

}
.multi_video_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.multi_video_wrapper iframe,
.multi_video_wrapper video {
  width: 70%;
  height: 600px;
}
.challenge-next-btn {
    background: var(--theme_pink);;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    line-height: 65px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.challenge-prev-btn {
    background: var(--theme_pink);;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    line-height: 65px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.disable-btns{
  background: grey;
}