 /* channel page */
 .bucketsubscribe .bucket-img {
    width: 71px;
    height: 73px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
    border-radius: 50px;
    box-shadow: 0px 4.005px 12.016px 0px rgba(0, 0, 0, 0.10);
}
 .favchannel-main-cus .channels-box {
     background: rgba(253, 253, 254, 0.5);
     box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
     border-radius: 28px;
     text-align: center;
     min-height: 200px;
     padding: 25px;
 }

 .favchannel-main-cus .like-icon {
     position: absolute;
     top: 11px;
     right: 32px;
 }

 .favchannel-main-cus .latest-series-name {
     display: grid;
     grid-template-columns: auto 120px;
     align-items: center;
     gap: 10px;
 }

 .favchannel-main-cus .latest-series-name .video-tile p {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     height: auto !important;
 }

 .favchannel-main-cus .latest-vid-img {
     height: 300px;
     object-fit: cover;
 }

 .favchannel-main-cus .like-fav-cus {
     position: absolute !important;
     bottom: 13px !important;
     right: 31px;
     background: rgba(188, 188, 188, 0.3);
     -webkit-backdrop-filter: blur(6px);
     backdrop-filter: blur(6px);
     color: var(--white);
     width: 45px;
     border-radius: 10px;
     padding: 5px 0;
     height: 40px;
     display: inline-block;
     text-align: center;
 }

 .favchannel-main-cus .like-fav-cus .fav-like-series {
     filter: initial !important;
 }

 .favchannel-main-cus .like-fav-cus img {
     filter: brightness(3.5);
 }

 .favchannel-main-cus .latest-video-cus .common-btn {
     padding: 0px 0px !important;
 }
 .bucketsubscribe p {
    border-radius: 7px;
    background: #FAEBF5;
    color: #CA3392;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}
.bucket-sub-wrapper {
    width: 312px;
    height: 202px;
}
 @media (max-width:446px) {
     .favourite-cus .chanel-content {
         gap: 9px;
     }

     .favourite-cus .bite-img-fav {
         max-width: 50% !important;
         width: auto !important;
         height: 150px;
         object-fit: cover;
         border-radius: 24px;
     }
 }

 @media (max-width:340px) {
     .favourite-cus .bite-img-fav {
         max-width: 50% !important;
     }

     .favchannel-main-cus .chanel-content {
         display: block;
     }

     .favchannel-main-cus .common-btn {
         padding: 5px 18px !important;
         font-size: 14px !important;
     }
 }

 @media (max-width:320px) {
     .favourite-cus .bite-img-fav {
         max-width: 100% !important;
     }

 }