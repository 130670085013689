 .price_tag {
     position: absolute;
     padding: 5px 10px;
     bottom: 0px;
     color: #FFF;
     font-family: Gilroy;
     font-size: 22px;
     font-style: normal;
     font-weight: 700;
     letter-spacing: -0.189px;
     border-radius: 0px 20px 0px 16px;
     background: #CA3392;
 }

 .second-value-price {
     color: #FFF;
     font-family: Gilroy;
     font-size: 14px;
     font-style: normal;
     font-weight: 600;
     line-height: 10.173px;
     letter-spacing: -0.189px;
 }

 .record-card-dropdown {
     border-radius: 20px 6px;
     border: 1px solid #E3E0FE !important;
     background: #FFF;
     overflow: hidden;
 }

 .card-bottom-container {
     display: flex;
     justify-content: space-between;
     align-items: end;
 }

 .choose-live-card-radio .radio-btn {
     accent-color: #CA3392;
     width: 32px;
     height: 32px;
 }

 .custom-dropdown-anchor {
     color: #07143A;
 }

 .custom-dropdown-anchor:hover {
     color: #07143A;
 }


 .modal-body .price-form-input,
 .price-input {
     width: 100%;
 }

 .modal-body input::-webkit-inner-spin-button {
     appearance: none;
     margin: 0;
 }

 .modal-body .price-input {
     /* padding: 15px 0px 15px 16px; */
     border-radius: 10px;
     /* border: 1px solid #D1D1D1; */

 }

 .modal-body .price-submit-btn {
     margin-top: 15px;
     width: 100%;
     border: none;
     padding: 15px 0;
     border-radius: 30px 10px;
     background: #CA3392;
     color: #FFF;
 }

 .common-btn-grey {
     display: inline-block;
     background-color: #D9D9D9;
     color: var(--white);
     border-radius: 30px 10px;
     padding: 18px 70px;
     text-align: center;
     font-size: 18px;
     border: none !important;
 }

 .dollar-sign {
     position: absolute;
     left: 60px;
     top: 50%;
     transform: translateY(-50%);
 }

 .images-count {
     color: #9DA4BF
 }

 .images-dropdown {
     position: absolute;
     right: 0px;
     top: 29px
 }

 .images-description {
     line-height: 25px;
     height: 70px;
     overflow-y: auto;
 }
 .image-show{
    width: 500px;
    height: 480px;
 }
 .image-show img{
    width: 100%;
    height: 100%;
 }
 .image-show-modal{
    padding-bottom: 65px;
    padding-top: 40px;
 }

 .input-file-image {
     position: absolute;
     left: 2px;
     width: 96%;
     height: 96%;
     opacity: 0;
 }

 .play-btn-video {
     position: absolute;
     top: 103px;
     right: 161px;
     height: 51px;
     width: 35px;
 }

 .add-audio {
     background: #faebf4;
     height: 118px;
     width: 121px;
     border-radius: 10px;
 }

 .create-audio-img {
    width: 50px;
    height: 50px;
    /* margin-bottom: 5px; */
}

.audio-name {
    color: #ca3392;
    width: 12ch;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

 .play-btn-add-video {
     position: absolute;
     height: 22px;
     width: 20px;
     top: 57px;
     left: 53px;
 }

 .select-video-input {
     cursor: pointer;
     position: absolute;
     left: 2px;
     top: 12px;
     width: 97%;
     height: 97%;
     opacity: 0;
 }
 .show-audio {
    height: 179px;
    background: #F0F1F4;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.show-audio img {
    height: 169px;
    width: 100px;
}
.show-audio_preview {
    height: 270px;
    background: #F0F1F4;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    flex-direction: column;
}


.show-audio_preview img {
    height: 169px;
    width: 100px;
}

 .first-price-detail {
     font-weight: 700;
     font-size: 30px;
     color: #ca3392;
 }

 .second-price-detail {
     color: #ca3392;
     font-weight: 600;
     font-size: 20px;
 }

 .image-detail-description {
     line-height: 34px;
 }

 .images-main-container {
     display: flex;
     flex-wrap: wrap;
     align-content: space-between;
     overflow: scroll;
 }

 .multi-images {
     height: 160px;
     width: 160px;
 }

 .multi-images img {
     height: 160px;
     width: 160px;
     border-radius: 10px;
 }

 .delete-detail-image {
     color: #ca3392;
     text-decoration: underline;
     display: flex;
     justify-content: center;
     font-weight: 600;
 }
 .detail-video-play{
        position: absolute;
        height: 38px;
        width: 31px;
        top: 66px;
        left: 69px;
    }
    
 @media (min-width:240px) and (max-width:300px) {
    .play-btn-video{
        right: 97px;
        width: 30px;
        top: 66px;
    }
 }

 @media (min-width:966px) and (max-width:1075px) {
    .play-btn-video{
        right: 136px;
    }
 }
 
.audio-main {
    width: 100%;
    background: white;
    box-shadow: 4px 5px 10px #00000030;
}
.audio-list-main{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}
.headphone {
    background: #faeaf4;
    height: 80px;
    width: 103px;
    border-radius: 10px;
}
.audio-cross-btn {
    top: -6px;
    right: -6px;
    width: 25px;
    height: 25px;
}
.add-audio-head{
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-image: repeating-linear-gradient(2deg, #ced4da, #ced4da 9px, transparent 9px, transparent 17px, #ced4da 17px), repeating-linear-gradient(92deg, #ced4da, #ced4da 9px, transparent 9px, transparent 17px, #ced4da 17px), repeating-linear-gradient(182deg, #ced4da, #ced4da 9px, transparent 9px, transparent 17px, #ced4da 17px), repeating-linear-gradient(272deg, #ced4da, #ced4da 9px, transparent 9px, transparent 17px, #ced4da 17px);
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
}
.cus-add-audio {
    background: #faebf4;
    height: 150px;
    min-width: 150px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.custom-image-preview{
    width: 100vw;
    height: 100vh;
}
.custom-image-preview img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media screen and (max-width:600px) {
    .image-show{
        width: 100%;
        height: 100%;
    }
    .image-show-modal{
        padding-bottom: 45px;
        padding-top: 30px;
    }
}
@media screen and (max-width:460px) {
    .image-show-modal{
        padding-bottom: 35px;
        padding-top: 15px;
    }
}
.audio-play{
    
        width: 429px;
        position: absolute;
        top: 214px;
        left: 68px;
    }


