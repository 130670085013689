.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-white {
    padding: 16px 42px;
    color: var(--btn-color);
    background-color: var(--white);
    border: 1px solid #E3E0FE;
    border-radius: 6px 20px;
}

.btn-white:hover,
.btn-white:active {
    padding: 16px 42px;
    color: var(--white);
    background-color: var(--theme_blue);
}

/* chanel start */
.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.see-all {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.chanel-content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    position: relative;
    border-radius: 24px 5px;
}

.chanel-content .chanl-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
}

.chanel-text p {
    line-height: 10px;
}

.bite-inner-img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
}

.bite-relative {
    position: relative;
}

.bite-wrapper .common-btn {
    display: inline-flex;
    border-radius: 13px 4px;
    padding: 5px 40px;
    border: none;
    gap: 10px;
    align-items: center;
}

.btn-play {
    position: absolute;
    top: 50%;
    border: none;
    background-color: transparent;
    left: 15%;
    transform: translateY(-50%);
}

.bite-wrapper p {
    line-height: 20px;
    text-overflow: ellipsis;
}

.challenge-video {
    position: relative;
}

.img-relative {
    width: 100%;
}

.video-bottom {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.video-bottom img {
    width: 55px;
    height: 55px;
}

.video-bottom p {
    line-height: 15px;
}

.video-bottom h6 {
    font-size: 16px;
}

.video-bg {
    position: relative;
}

.bg-image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
}


.play-icon {
    position: absolute;
    background: #cc09a5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 28px 33px;
    bottom: 20px;
    right: 10px;
    border-color: #cc09a5;
    outline: none;
    box-shadow: none;
    border: 1px transparent;
}

.play-icon img {
    width: 49px;
}

.video-detail {
    display: flex;
}

.chllge-img {
    width: 63px;
    height: 63px;
    margin-right: 10px;
}

.grid-frame {
    display: grid;
    grid-template-columns: 500px 500px 500px;
    gap: 30px;
    cursor: pointer;
    height: 440px;
}

.grid-column {
    background-color: var(--white);
    padding: 10px;
    border-radius: 25px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.14);
    height: 430px;
    width: 100%;
    left: 5px;
}

.challene-wrapper {
    overflow-x: scroll;
}

/* width */
::-webkit-scrollbar {
    display: none;
}

.challene-wrapper {
    width: 100%;
    overflow-x: scroll;
}

.comming-soon .owl-carousel .owl-nav.disabled {
    display: none !important;
}

.img-video,
.img-relative,
.bite-img {
    border-radius: 13px;
}

.img-video {
    width: 100%;
}

/* challenge end */

/* modal  */
.play-video span.carousel-control-prev-icon {
    width: 80px;
    height: 80px;
    background: var(--theme_pink);
    position: absolute;
    left: -120px;
    border-radius: 50%;
    font-size: 50px;
}

.play-video .carousel-control-next-icon {
    right: -120px;
    width: 80px;
    height: 80px;
    background: var(--theme_pink);
    position: absolute;
    border-radius: 50%;
    font-size: 50px;
}

.play-video .modal-content {
    background-color: transparent;
    border: none;
}

.play-video .slider-image {
    border-radius: 30px;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.2);
}

.play-video .slider-images {
    border-radius: 30px;
}

.play-video .video-bottom {
    position: absolute;
    bottom: 10px;
    left: -40px;
    text-align: left;
}

.carousel-control-prev,

.carousel-control-next {
    width: 0;
}

.play-video .like-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 55px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 0;
}

.play-video .share-btn {
    background: rgba(188, 188, 188, 0.3);
    backdrop-filter: blur(6px);
    color: var(--white);
    width: 55px;
    border-radius: 10px;
    text-align: center;
    padding: 5px 0;
    margin-top: 10px;
}

.respns-wrapper {
    position: absolute;
    bottom: 20%;
    right: 30px;
}

.slider-image {
    position: relative;
}

.channelbite-series {
    width: 168px;
    height: 147px;
    object-fit: cover;
    border-radius: 8.63px;
}

.create-series .channel-bites-content {
    border-radius: 15px;
}

.series-text h6 {
    font-size: 20px;
    font-weight: 600;
    color: var(--channel-text);
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-line;
}

.seriesimg-wrapper {
    width: 178px;
    height: 153px;
}

.series-text p {
    color: #A4B2B4;
}

.video-createseries {
    height: 198px;
    width: 100%;
}

.create-series .series-box .common-btn {
    padding: 7px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dotsmain {
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    background: hsla(0, 0%, 74%, .3);
    border-radius: 10px;
    color: var(--white);
    display: inline-block;
    padding: 6px 0;
    position: absolute;
    right: 4px;
    top: 4px;
    text-align: center;
    width: 38px;
    height: 38px;
}

.start-live {
    border: 0;
    background-color: #ca3392;
    color: #fff;
    border-radius: 10px 1px 10px 1px;
    padding: 3px 10px;
    font-size: 12px;
    margin-top: 13px;
}

.series-text h6 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-line;
}

@media screen and (max-width:1920px) {
    .bite .bite-img {
        max-width: 120px;
        ;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    img.bite-img {
        max-height: 150px;
        min-height: 150px;
        height: 100%;
    }
}

@media screen and (max-width: 1700px) {
    .channelbite-series {
        width: 120px;
        height: 120px;
    }
}

@media screen and (max-width: 1600px) {
    .bite .bite-img {
        max-width: 150px;
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .bite .bite-img {
        max-width: 80px;
    }

    .chanel-content {
        gap: 12px;
        padding: 10px;
    }

    .chanel-text {
        gap: 5px !important;
        align-items: flex-start !important;
    }

    .bite-wrapper .common-btn {
        padding: 5px 35px;
        font-size: 16px;
    }

    .bite-wrapper h4 {
        font-size: 18px;
    }

    .bite-wrapper .common-btn {
        padding: 5px 28px;
        font-size: 14px;
    }

    .series-text h6 {
        font-size: 16px;
    }

    .create-series .series-box p {
        font-size: 16px;
    }

    .create-series .series-box .common-btn {
        padding: 7px 10px !important;
        width: 100%;
    }

    .create-series .channel-bites-content {
        gap: 12px !important;
    }
}

@media screen and (max-width:1200px) {
    .chanel-content .chanl-img {
        width: 60px;
        height: 60px;
    }

    .bite .bite-relative {
        width: 100%;
    }

    .bite .bite-wrapper {
        margin: 0;
    }

    .btn-play {
        top: 120px;
        left: 40%;
    }

    .bite-wrapper {
        margin: 0;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .chanel-text h5 {
        font-size: 18px;
    }
}

@media screen and (max-width:991px) {
    ::-webkit-scrollbar {
        display: none !important;
    }

    .bite .row.gy-4 {
        --bs-gutter-y: 0 !important;
    }

    .btn-play {
        top: 80px;
        left: 10%;
    }

    .video-bg:after {
        width: 100%;
    }

    .play-icon img {
        width: 38px;
    }

    .play-icon {
        width: 90px;
        height: 90px;
        padding: 28px 33px;
        bottom: 30px;
        right: 0;
    }

    .response-padding {
        padding: 15px 0;
    }

    .response-col {
        padding: 15px 10px;
    }

    .channel .g-5,
    .gy-5 {
        --bs-gutter-y: 1rem !important;
    }

    .bite-wrapper {
        margin: 0;
    }

    .play-video span.carousel-control-prev-icon {
        left: -40px;
    }

    .play-video .carousel-control-next-icon {
        right: -40px;
    }

}

@media screen and (max-width:767px) {

    .bite .bite-relative {
        width: auto;
    }

    .bite-wrapper p {
        max-width: 160px;
    }

    .btn-play img {
        width: 40px;
    }

    .btn-play {
        top: 95px;
        left: 21%;
    }

    .play-video span.carousel-control-prev-icon,
    .play-video .carousel-control-next-icon {
        width: 60px;
        height: 60px;
        font-size: 40px;
    }

    .play-video span.carousel-control-prev-icon {
        left: 0;
    }

    .play-video .carousel-control-next-icon {
        right: 0;
    }

    .grid-column {
        height: 330px;
    }

    .grid-frame {
        grid-template-columns: 350px 350px 350px;
        gap: 20px;
        height: 330px;
    }

    .play-icon {
        width: 60px;
        height: 60px;
    }

    .play-icon img {
        width: 25px;
    }

    .play-icon {
        padding: 20px 24px;
    }

}

@media screen and (max-width:575px) {

    .btn-play {
        left: 15%;
    }

    .grid-frame {
        grid-template-columns: 250px 250px 250px;
        height: 300px;
    }

    .grid-column {
        height: 290px;
    }

    .chllge-img {
        width: 55px;
        height: 50px;
        object-fit: cover;
    }

    .play-icon {
        width: 60px;
        height: 60px;
        bottom: 30px;
        right: 10px;
    }

    .chanel-content {
        gap: 22px;
        padding: 20px;
    }

    .bite .bite-img {
        max-width: 40%;
    }
}

@media screen and (max-width:480px) {

    /* .interactive-main .interactive .bite-wrapper {
        text-align: left !important;
    } */
    .bite .bite-relative {
        width: 50%;
    }

    .bite-wrapper p {
        max-width: 100%;
    }

    .btn-play {
        top: 135px;
        left: 45%;
    }

    .play-video span.carousel-control-prev-icon {
        width: 40px;
        height: 40px;
        left: -10px;
        font-size: 27px;
    }

    .play-video .carousel-control-next-icon {
        width: 40px;
        height: 40px;
        right: -10px;
        font-size: 27px;
    }

    .video-bottom img {
        width: 35px;
        height: 35px;
    }

    .play-video .video-bottom {
        left: -30px;
    }

    .respns-wrapper {
        right: 10px;
    }

    .play-video .like-btn,
    .play-video .share-btn {
        width: 45px;
    }

    .chanel-content {
        gap: 20px;
        padding: 20px;
    }
}

@media screen and (max-width:420px) {
    .bite .bite-relative {
        width: 100%;
    }

    .btn-play {
        top: 130px;
        left: 45%;
    }

    .chanel-content {
        gap: 12px;
        padding: 15px 30px 15px 10px;
    }

    img.bite-img {
        max-height: 120px;
        min-height: 120px;
    }

    .bite-wrapper h4 {
        font-size: 18px !important;
    }

    .bite .bite-wrapper .common-btn {
        padding: 5px 25px;
        font-size: 16px;
    }

    .bite .bite-img {
        max-width: 50%;
    }

}

@media screen and (max-width:320px) {
    .bite .bite-wrapper .common-btn {
        padding: 5px 20px;
        font-size: 14px;
    }

    .bite-wrapper h4 {
        font-size: 16px !important;
    }

    .chanel-text h5,
    .chanel-text p {
        font-size: 15px;
        margin: 5px 0;
    }

    img.bite-img {
        max-height: 105px;
        min-height: 100px;
    }

    .bite .bite-img {
        max-width: 40%;
    }

    .bite img.bite-img {
        max-width: initial !important;
    }

    .chanel-content {
        padding: 15px 12px 15px 10px !important;
    }

    .channelbite-series {
        width: 100%;
        margin-bottom: 8px;
    }
}

.app_videos {
    position: relative;
    width: 68%;
    max-width: 450px;
    height: 100%;
    /* background-color: black; */
    scroll-snap-type: x mandatory;
    border-radius: 20px;
    margin: 0px auto;
}

.app_videos::-webkit-scrollbar {
    display: none;
}

.videoCard {
    position: relative;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
}

.videoCard_player {
    object-fit: fill;
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.cast-upload {
    border: 1px solid #CA3392;
    width: 180px;
    height: 180px;
    border-radius: 100px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    background: #FAEBF5;
}

.lighttext {
    color: #ECBFDB;
}

.modal-cast .video-file-schedule {
    border-radius: 100px;
}

.tag-add {
    background: var(--theme_pink);
    color: #fff;
    border: 0;
    width: 100%;
    max-height: 100%;
    height: 54px;
    border-radius: 10px;
}

.input-suggestion {
    border-radius: 25px;
    border: 1px solid var(--theme_pink);
    ;
    background: #FBEFF7;
    padding: 8px 10px;
    color: var(--theme_pink);
    ;
}

/* create-series css */

.create-series-wrapper {
    border-radius: 30px;
    background: var(--white);
    box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.10);
}

.create-series-wrapper textarea {
    height: 155px;
}

.newcreate-series-wrapper textarea {
    height: 100%;
}

.pink-add {
    color: var(--theme_pink);
    text-decoration-line: underline;
}

.place-grey {
    color: #B8B8B8;
}

.place-grey::placeholder {
    color: #B8B8B8;
}

.video-upload {
    border-radius: 12px;
    width: 100%;
    height: 250px;
    border: 2px solid #8e8e8e;
}

.video-upload video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.playbtn-image {
    position: absolute;
    top: 45%;
    right: 45%;
}

.video-select-thumb {
    border-radius: 6.685px;
    border: 1.668px dashed #C7C7C7;
    background: #FFF;
}

.video-file {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.thumb-upl-text {
    position: absolute;
    top: 60%;
    right: 40%;
    line-height: 26px;
    letter-spacing: -0.408px;
    color: #CBCBCB;
    text-decoration-line: underline;
}

.thumb-img {
    position: absolute;
    top: 40%;
    right: 46%;
}

.add-upl-text {
    position: absolute;
    top: 60%;
    right: 40%;
    line-height: 26px;
    letter-spacing: -0.408px;
    color: #CBCBCB;
    text-decoration-line: underline;
}

.add-video-img {
    position: absolute;
    top: 45%;
    right: 42%;
}

.cast-video-img {
    top: 40%;
    right: 44%;
}

.add-cast-head {
    width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 25px;
}

.add-epi-head {
    overflow-x: auto;
    display: flex;
    gap: 25px;
    margin-right: 23px;
}

.add-sub-item {
    width: 250px;
    border-radius: 6.685px;
    border: 0.668px solid #C7C7C7;
    background: #FFF;
    margin-top: 33px;
}

.cast-item-img {
    border-radius: 100%;
    object-fit: cover;
    width: 113px;
    height: 113px;
}

.cast-item-text {
    color: #9DA4BF;
    text-align: center;
    line-height: normal;
}

.cast-item-head {
    color: #12153D;
    text-align: center;
    line-height: normal;
    margin: 10px 0px;
}

.episodeimg-create {
    border-radius: 28px 28px 0px 0px;
    width: 255px;
    height: 191px;
    object-fit: cover;
}

.add-sub-itemepi {
    border-radius: 28px 28px 20px 20px;
    background: #fff;
}

.cast-btn {
    width: 50%;
}

.video-episode-create {
    width: 100%;
    height: 259px;
    border: 1px dashed #C7C7C7;
    border-radius: 6px;
}

.play-epi {
    position: absolute;
    top: 39%;
    left: 42%;

}

.episode-preview {
    width: 602px;

}

.episode-preview img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.episode-preview-after {
    display: none;
}

.video-clip-create {
    width: 100%;
    height: 198px;
    border: 1px dashed #C7C7C7;
    border-radius: 6px;
}

.clipimg-create {
    width: 436px;
    height: 198px;
    object-fit: cover;
    border-radius: 6px;
    background-color: #903232;
}

.cro-epi {
    position: absolute;
    top: -14px;
    right: -12px;
}

.episode-add-detail {
    width: 80%;
}

.episode-anchor {
    width: 20%;
}

.cro-episode {
    position: absolute;
    top: -14px;
    right: -4px;
}

.video-cast-create.position-relative {
    width: 170px;
    height: 230px;
    border: 1px dashed #C7C7C7;
    border-radius: 6px;
}

.cast-anchor {
    width: 15%;
}

.cast-anchor-detail {
    width: 85%;
}

.add-sub-itemp-3 {
    border-radius: 6px;
    border: 1px solid #C7C7C7;
    background: #FFF;
    padding: 16px 6px;
    height: 230px;
}

.modal-radius {
    border-radius: 30px;
    box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.10);
}

.h-150 {
    height: 150px;
}

.whitecreate-btn {
    border-radius: 17px 5px;
    border: 1px solid #E3E0FE;
    background: #FFF;
    padding: 18px 77px;
}


textarea {
    resize: none;
}

/* delete series modal  */
.delete-content p {
    font-size: 20px;
}

.no-btn {
    margin: 0px 30px;
    display: inline-block;
    color: black;
    border-radius: 30px 10px;
    padding: 18px 70px;
    text-align: center;
    font-size: 18px;
    border: 1px solid black !important;
}

.grey-btn {
    background-color: #f0f0f0 !important;
    color: black !important;
    display: inline-block;
    border-radius: 30px 10px;
    padding: 18px 70px;
    text-align: center;
    font-size: 18px;
    border: none !important;
}

.grey-btn:hover {
    background-color: #f0f0f0 !important;
    color: black !important;
}

#custom-dropdown-menu {
    transform: translate(-130px, 38.7333px) !important;
}

#custom-drop-challenge {
    transform: translate(-130px, 38.7333px) !important;
}


@media screen and (max-width:1400px) {
    .cast-anchor {
        width: 23%;
    }

    .cast-anchor-detail {
        width: 70%;
    }
}

@media screen and (max-width:1200px) {
    .cast-anchor {
        width: 20%;
    }

    .cast-anchor-detail {
        width: 80%;
    }

    .episode-add-detail {
        width: 70%;
    }

    .episode-anchor {
        width: 30%;
    }
}

@media (max-width:991px) {
    #custom-drop-challenge {
        transform: translate(0px, 38.7333px) !important;
    }
}

@media (max-width:768px) {
    .video-episode-create {
        width: 100%;
        height: 200px;
    }

    .add-cast-head {
        margin-left: 0px;
    }

    .episode-anchor {
        width: 100%;
        margin-bottom: 37px;
    }

    .episode-add-detail,
    .cast-anchor,
    .video-cast-create {
        width: 100% !important;
    }

    #custom-dropdown-menu {
        transform: translate(2px, 38.7333px) !important;
    }
}

.cast-after-pre {
    display: none;
}

.thumbnail-preview {
    width: 180px;
    height: 180px;
    border-radius: 100px;
    object-fit: cover;
}

/* MyDropdown.css */
#custom-dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    padding: 1px 7px !important;
}

#custom-dropdown-toggle:focus,
#custom-dropdown-toggle:active,
#custom-dropdown-toggle:hover {
    background-color: transparent !important;
}


#custom-dropdown-toggle+.dropdown-menu .dropdown-item {
    color: #333 !important;
}

.btn-primary {
    color: transparent !important;
}

.custom-dropdown-toggle a:hover {
    color: transparent !important;
}

.custom-dropdown-toggle .dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent !important;
}

#custom-dropdown-toggle:active,
#custom-dropdown-toggle:focus,
#custom-dropdown-toggle:hover,
#custom-dropdown-toggle:checked,
#custom-dropdown-toggle:active+.dropdown-menu,
#custom-dropdown-toggle:focus+.dropdown-menu,
#custom-dropdown-toggle:hover+.dropdown-menu,
#custom-dropdown-toggle:checked+.dropdown-menu,
#custom-dropdown-toggle.active+.dropdown-menu,
#custom-dropdown-toggle:active+.dropdown-toggle,
#custom-dropdown-toggle:focus+.dropdown-toggle,
#custom-dropdown-toggle:hover+.dropdown-toggle,
#custom-dropdown-toggle:checked+.dropdown-toggle,
#custom-dropdown-toggle.active+.dropdown-toggle,
.show>#custom-dropdown-toggle.dropdown-toggle {
    box-shadow: none !important;
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: none !important;
    border-radius: .25rem;
}