.following_profile img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 40px;
}

.following_main {
    display: flex;
    gap: 10px;
    align-items: center;
}

.following_tab {
    background: #fff;
    padding-left: 0;
}

.following_heading {
    padding: 14px 20px;
    background: #e7e8ec;
    height: 50px;
}

.following_listing li {
    padding: 10px 20px;
    border-bottom: 0.5px solid rgba(48, 48, 48, 0.20);
}

.following_listing {
    height: over;
    overflow-y: scroll;
    height: 1000px;
}

.right-aside {
    position: fixed;
    top: 87px;
    min-width: 366px;
    width: 366px;
    bottom: 0;
    z-index: 9;
    background-color: #fff;
    right: 0;
}

/* .center-content {
    min-width: calc(100% - 300px);
    width: calc(100% - 300px);
    margin: 0 400px 0px 250px;
} */
.center-content .center-container {
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    width: 100%;
}

.feed-user {
    gap: 30px;
    display: grid;
    grid-template-columns: 40px 1fr auto;
    align-items: center;
    padding: 11px 20px;
}

.feed-user .userimg {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.post-options {
    display: flex;
    align-items: center;
}

/* .post-options .bookmark-icon img {
    width: 18px;
    object-fit: contain;
} */
.feed_details {
    background: #fff;
    border-radius: 30px;
}

.feed_image {
    width: 100%;
    height: 450px;
}

.feed_inner_images {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.social_icon_feed {
    display: flex;
    align-items: center;
    gap: 12px;
    background: rgb(7 20 58 / 10%);
    padding: 18px 20px;
}
.description_feed{
    padding: 0px 20px;
}