.video-container {
  width: 100%;
  position: unset !important;
}
.webview-chat-scroll {
  height: calc(100vh - 430px);
  overflow-y: auto;
}
.webview-chat-heading{
  background-color: #DFE7EC;
}
.profile-chat img{
  width: 40px;
  height: 40px;
}
.video-container {
  position: static!important;
  width: 100%;
  height: 400px;
}

/* 
.full-screen-video {
      width: 100%;
      height: 100%;
      object-fit: cover; 
}

.chat-overlay{
  flex:1,
  
} */