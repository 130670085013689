.tip-box h5{
   color: var(--side-text);
   font-size: 20px;
}
.tip-box {
    border-radius: 40px;
}
.bucketvideo_wrapper{
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.upload-profile img {
    width: 110px;
    height: 80px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.upload_inner {
    display: grid;
    grid-template-columns: 110px auto 40px;
    gap: 15px;
    align-items: center;
}
.bucketdash_inner {
    display: grid;
    grid-template-columns: 100px auto 73px;
    gap: 15px;
    align-items: end;
    border-radius: 20px;
    border: 1px solid #E3E0FE;
    padding: 11px;
}
.uploadedvideo_listed {
    height: 310px;
    overflow-y: auto;
    margin-bottom: 0;
}
.cursor-pointer{
    cursor: pointer;
}
.bucketprice p{
    color: #303030;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.tipsdash_wrapper .upload-profile img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.tipsdash_wrapper .upload-profile {
    width: 50px;
    height: 50px;
}
.tipsdash_wrapper .upload_inner{
    display: grid;
    grid-template-columns: 50px auto 73px;
    gap: 15px;
    align-items: center;
}
.bucketdash_inner .upload-profile img {
    width: 100px;
    height: 100px;
    border-radius:10px ;
}
/* table */
.subscribers-table {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 25px;
    /* vertical-align: middle; */
}
.subs-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.subscribers-table td {
    font-size: 20px;
    font-weight: 500;
}
.subscribers-table .table>tbody {
    vertical-align: middle;
}
.subscribers-table .view-btn {
    border-radius: 20px;
    background: #CA3392;
    display: flex;
    margin: auto;
}
.subscribers-table .table>:not(:last-child)>:last-child>* {
    border-bottom-color: #c6c6c680;
}