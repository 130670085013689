.stream-btn .btn-blue {
    padding: 16px 42px;
    border-radius: 6px 20px;
}

h4 {
    font-weight: 600;
}

.btn-blue {
    padding: 16px 42px;
    border: 1px solid #E3E0FE;
    border-radius: 20px 6px;
}

.btn-white:hover,
.btn-white:active {
    /* padding: 16px 42px; */
    color: var(--white);
    background-color: var(--theme_blue);
}

.see-all {
    border: none;
    background-color: transparent;
    outline: none;
    box-shadow: none;
}

.epsiode-slider .owl-carousel .item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.epsoide-slider-wrapper {
    position: relative;
    width: 100%;
}

.epsoide-title {
    background-color: #ffffffbd;
    padding: 10px 10px;
    border-radius: 0 0 13px 13px;

}

.trending-number {
    position: absolute;
    top: 10px;
    right: 30px;
}

.eps-play {
    border: none;
    position: absolute;
    top: 39%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%);
}

.epsiode-slider .owl-carousel .owl-item .eps-play img {
    width: 50px !important;
}

.chanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.epsiode .owl-dots,
.epsiode .owl-nav {
    display: none;
}


/* video-bannr start */
.bgwhite {
    background: #FDFDFE;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
    border-radius: 42px;
    padding: 10px 10px;
    width: 290px;
}

.mvie-title {
    width: 50px;
    height: 50px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.mv-title {
    width: 50px;
    height: 50px;

}

.movie-wrapper {
    padding: 40px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(29px);
}

.frame-video-img img {
    width: 100%;
}

/* video-bannr end */

@media screen and (max-width:1200px) {
    .movie-detail .stream-btn .btn-blue {
        padding: 12px 30px;
        border-radius: 6px 20px;
    }

    .movie-wrapper {
        padding: 20px 30px;
    }

    .bgwhite .gap-4 {
        gap: 1rem !important;
    }

    .movie-wrapper .gap-5,
    .movie-detail .gap-5 {
        gap: 30px !important;
    }

    .mvie-title {
        width: 40px;
        height: 40px;
    }

    .mv-title {
        width: 40px;
        height: 40px;
    }

    .bgwhite {
        width: 235px;
    }

    .movie-detail .stream-btn {
        gap: 10px;
    }
}

@media screen and (max-width:991px) {

    .bgwhite .gap-4 {
        gap: 0.5rem !important;
    }

    .movie-wrapper .gap-5,
    .movie-detail .gap-5 {
        gap: 30px !important;
    }

}

@media screen and (max-width:767px) {
    .bgwhite {
        width: 225px;
    }

    .movie-wrapper .gap-5,
    .movie-detail .gap-5 {
        gap: 15px !important;
    }

    .bgwhite h4 {
        font-size: 18px !important;
    }

    .bgwhite {
        width: 200px;
    }

}

.more_epsoide .owl-dots,
.more_epsoide .owl-nav {
    display: none;
}