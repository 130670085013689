.following_profile img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 40px;
}

.following_main {
    display: flex;
    gap: 10px;
    align-items: center;
}

.following_tab {
    background: #fff;
    padding-left: 0;
}

.following_heading {
    padding: 14px 20px;
    background: #e7e8ec;
    height: 50px;
}

.following_listing li {
    padding: 10px 20px;
    border-bottom: 0.5px solid rgba(48, 48, 48, 0.20);
}

.following_listing {
    height: over;
    overflow-y: scroll;
    height: 1000px;
}

.right-aside {
    position: fixed;
    top: 87px;
    min-width: 366px;
    width: 366px;
    bottom: 0;
    z-index: 9;
    background-color: #fff;
    right: 0;
}

/* .center-content {
    min-width: calc(100% - 300px);
    width: calc(100% - 300px);
    margin: 0 400px 0px 250px;
} */
.center-content .center-container {
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    width: 100%;
}

.feed-user {
    gap: 30px;
    display: grid;
    grid-template-columns: 40px 1fr auto;
    align-items: center;
    padding: 11px 20px;
}

.feed-user .userimg {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.post-options {
    display: flex;
    align-items: center;
}

.post-options img {
    background: none !important;
}

/* .post-options .bookmark-icon img {
    width: 18px;
    object-fit: contain;
} */
.feed_details {
    background: #fff;
    border-radius: 30px;
}

.feed_image {
    width: 100%;
    height: 450px;
}

.feed_inner_images {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.social_icon_feed {
    display: flex;
    align-items: center;
    gap: 12px;
    background: rgb(7 20 58 / 10%);
    padding: 18px 20px;
}

.description_feed {
    padding: 0px 20px;
}

.feed_input {
    background-color: white;
    /* height: 100px; */
    border-radius: 30px;
}

.feed-user-top {
    gap: 10px;
    display: grid;
    grid-template-columns: 40px 1fr auto;
    padding: 30px 20px;
}

.feed-user-top .userimg {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.feed-img {
    width: 100%;
    padding: 18px 9px 21px 0px;
}

.post-input {
    border: 1px solid #D1D1D1;
    width: 100%;
    padding: 20px 150px 20px 20px;
    border-radius: 30px;
    margin: 10px -10px;
}

.post-btn {
    position: absolute;
    background: var(--theme_pink);
    border-radius: 30px;
    padding: 10px 30px;
    right: 30px;
    top: 38px;
    color: white;
    border: none;
    width: 130px;
}

.create-post-textarea {
    border-radius: 25px;
    padding: 15px;
}

.create-post-img {
    width: 120px;
    height: 120px;
    border-radius: 15px !important;
    margin: 10px 0px;
}

.create-post-select-img {
    width: 80px;
    height: 80px;
    border-radius: 15px !important;
    margin: 30px 0px;
}

.create-post-select-span {
    border-radius: 10px;
    background-image: repeating-linear-gradient(2deg, #ced4da, #ced4da 9px, transparent 9px, transparent 17px, #ced4da 17px), repeating-linear-gradient(92deg, #ced4da, #ced4da 9px, transparent 9px, transparent 17px, #ced4da 17px), repeating-linear-gradient(182deg, #ced4da, #ced4da 9px, transparent 9px, transparent 17px, #ced4da 17px), repeating-linear-gradient(272deg, #ced4da, #ced4da 9px, transparent 9px, transparent 17px, #ced4da 17px);
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
}

.create-post-cross-btn {
    position: absolute;
    top: 3px;
    right: -8px;
    width: 20px;
    height: 20px;
}

.feed-upload {
    background: #faebf5;
    width: 20%;
    border-radius: 20px;
    padding: 5px;
    margin-left: 8px;
}

.upload-img {
    padding-left: 5px;
    padding-right: 3px;
}

.share-friend-img {
    text-align: center;
}

.icon-text {
    color: #737373
}

.main-icon {
    display: flex;
    justify-content: space-around;
    margin: 60px 0px 20px 0px;
    flex-wrap: wrap;
}

.modal-side-text {
    font-size: 16px;
    line-height: 20px;
    color: #737373;
}

.modal_social_icon_feed {
    padding: 10px !important;
    background: #fff !important;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    width: 97%;
}

.detail-modal-img {
    width: 100%;
    /* max-height: 90vh; */
    object-fit: cover;
    height: 100%;
}

.reaction-icon-img {
    padding: 4px;
    border-radius: 50%;
}

.active-reaction {
    background-color: rgb(190, 190, 190);
}

.comment-section .commentImg {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.comment-text {
    background: #F9F9F9;
    border-radius: 10px
}

.reply-section .commentImg {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.comment-post-input {
    background: #F9F9F9;
    width: 94%;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 4px 5px 10px #00000030;
}

.comment-input-feed {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    border: none;
    background: #F9F9F9;;
}

.comment-post-btn {
    position: absolute;
    left: 91%;
    top: 92%;
    border-radius: 20px;
    padding: 2px 23px;
    background: #ca3392;
    border: none;
    color: white;
}

.smile-img {
    padding: 7px 10px;
    border-right:1px solid #b6b6b65c;  
}

.color-picker {
    position: absolute;
    top: 47%;
}