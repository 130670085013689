.bites-home .chanel-content {
    border-radius: 24px 5px !important;
}

.bites-home .response-col {
    padding: 15px 8px;
}

.bites-home .trending-text {
    left: 14px;
    top: 15px;
}

.bites-home .bite-inner-img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    object-fit: contain !important;
    background: #fff;
}

.bites-home .talent-relative {
    width: 134px;
}

@media screen and (max-width:1600px) {
    .bites-home .bite-wrapper .common-btn {
        padding: 5px 35px !important;
        font-size: 16px !important;
        margin-top: 8px;
    }

    .bites-home .bite .bite-img {
        max-width: 134px !important;
    }
}

@media screen and (max-width:320px) {
    .bites-home .bite .bite-img {
        max-width: initial !important;
    }
    .bites-home .trending-text {
        left: 11px;
        top: 15px;
    }
}

@media screen and (max-width:630px) {
    .bites-home .trending-text {
        left: 20px;
        top: 25px;
    }
}

